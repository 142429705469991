<template>
    <div :class="rootClasses">
      <div class="container">

        <h2 :class="textAlignment" v-if="data.title">{{data.title}}</h2>
        <span :class="textAlignment" v-if="data.text" v-html="data.text"></span>

        <div :class="buttonAlignment">

          <button 
              class="button"
              v-for="(button, key) in data.buttons"
              :key="key"
              >
              {{button.buttonText}}
          </button>

        </div>

      </div>
    </div>
</template>

<script>
export default {
  name: 'Text',
  props: {
      data: {
          type: Object,
          default: () => {}
      }
  },
  computed: {
    rootClasses() {
      let result = {
        ['cs-text-block']: true,
        ['theme-block']: true
      };

      if (this.data?.hasBackground) result = {
        ...result,
        ...{
          ['has-background']: true,
          ['theme-bg-colored']: true
        }
      }

      return result;
    },
    textAlignment() {
      return  (this.data.textAlign == "center")  ? "center" : "left";
    },     
    buttonAlignment() {
      return  (this.data.textAlign == "center")  ? "button-center" : "button-left";
    },   
  },
}
</script>

<style lang="scss" scoped>
@import '../styles/main.scss';
.center{
    text-align: center;
}
.left{
    text-align: left;
}
.button-center{
  display: flex;
  justify-content: center;
}
.button-left{
  display: flex;
  justify-content: left;
}
.button{
  margin: $block_space_sm;
  @include theme-button;
  width: 180px;
}
h2{
  margin: $block_space_sm;
  @include title;
}
span.left,
span.center {
  margin: $block_space_sm;
  color: $primary_text_color;

  ::v-deep {
    * {
      color: $primary_text_color;
    }
  }
}
</style>
